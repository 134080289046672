$mainBlue: #057DCC; // #057DCC
$mainGreen: #29AE99; // #29AE99
$greyHome: #484848;
$darkGrey: #333;
$mainPink: #EA4C64;
$appBarHeight: 66px;
$mainWhite: #f9f8f6;

@font-face {
    font-family: 'Raleway';
    src: local('Raleway'), url(./assets/fonts/Raleway/Raleway-Regular.ttf) format('truetype');
}

@font-face {
    font-family: 'Poppins';
    src: local('Poppins'), url(./assets/fonts/Poppins/Poppins-Regular.ttf) format('truetype');
}

body {
    background-color: $mainWhite;
}

#app {
    height: 88vh;
}

a {
    color: $mainBlue;
}

html {
    font-family: "Poppins";
}


.autocomplete_disabled > div > input {
    background-color: #f0f0f0 !important;
    color: black !important;
}

.breadcrumb {
    font-size: 1.1rem !important;
}

.breadcrumbLast {
    font-size: 1.3rem !important;
}

.MuiInputLabel-outlined {
    transform: translate(14px, 15px) scale(1);
}

// navbar icon height
.MuiIcon-root {
    font-size: 2rem !important;
    margin: 0 0.2rem !important;
}

// expansion panel button
.MuiIconButton-root {
    color: rgb(48, 5, 5);
}

.MuiExpansionPanelSummary-expandIcon {
    margin-left: -50px !important;
}

.MuiExpansionPanelSummary-expandIcon {
    color: white !important;
}

// navbar searchbar (event)
input#searchBar {
    background-color: white;
    color: #333 !important;
}

// search bar active border's color
input[type='text']:not(.browser-default),
input[type='text']:not(.browser-default):focus:not([readonly]),
input[type='password']:not(.browser-default) {
    border-bottom: 1px solid $darkGrey;
    box-shadow: none;
}

// input[type=text]:not(.browser-default), input[type=text]:not(.browser-default):focus:not([readonly]), input[type=password]:not(.browser-default) {
//     border-bottom: none;
//     box-shadow: none;
// }

// navbar searchBar
input.sieau-input.form-control,
input.autocomplete.sieau-input,
.sieau-input.form-control,
.autocomplete.sieau-input {
    border-radius: 5px;
    // margin-top: 5px;
    padding-left: 5px;
    background-color: white;
    border: 1px solid lightgrey;
}

// style Autocomplete MUI
div.MuiAutocomplete-inputRoot.MuiInputBase-root.MuiOutlinedInput-root {
    height: 50px !important;
}
input.MuiInputBase-input.MuiOutlinedInput-input.MuiAutocomplete-input {
    border-bottom: none;
}

.MuiGrid-grid-xs-2 {
    max-width: 28% !important;
    flex-basis: 16.666667%;
}

input[type='text']:not(.browser-default),
input[type='text']:not(.browser-default):focus:not([readonly]) {
    color: black;
}

// Autocomplete searchBar
.MuiFormControl-fullWidth {
    width: 100%;
    background: white;
    border: none;
    border-radius: 5px;
    height: 40px;
}

.MuiListItemIcon-root {
    min-width: 0;
}

.fixed_left_bar {
    width: 48px;
}

.MuiFormControl-marginNormal {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
    height: 40px important;
}

.MuiAutocomplete-inputRoot[class*='MuiOutlinedInput-root'] {
    height: 40px !important;
}

.makeStyles-inputBase-204 {
    border: none !important;
}

.MuiAutocomplete-inputRoot[class*='MuiOutlinedInput-root'] {
    padding: 0;
    height: 40px !important;
}

.MuiAutocomplete-inputRoot[class*='MuiOutlinedInput-root'] .MuiAutocomplete-input {
    padding: 0 !important;
}

.MuiAutocomplete-inputRoot[class*='MuiOutlinedInput-root'] .MuiAutocomplete-input:first-child {
    height: 31px;
}

.MuiAutocomplete-tag {
    margin: 0;
}

.MuiToolbar-root.MuiToolbar-regular.MuiToolbar-gutters {
    transform: translateY(-2px);
}

.MuiToolbar-regular {
    min-height: 0 !important;
}

// home searchBar
input:not([type]):focus:not([readonly]),
input[type='text']:not(.browser-default):focus:not([readonly]),
input[type='password']:not(.browser-default):focus:not([readonly]),
input[type='email']:not(.browser-default):focus:not([readonly]),
input[type='url']:not(.browser-default):focus:not([readonly]),
input[type='time']:not(.browser-default):focus:not([readonly]),
input[type='date']:not(.browser-default):focus:not([readonly]),
input[type='datetime']:not(.browser-default):focus:not([readonly]),
input[type='datetime-local']:not(.browser-default):focus:not([readonly]),
input[type='tel']:not(.browser-default):focus:not([readonly]),
input[type='number']:not(.browser-default):focus:not([readonly]),
input[type='search']:not(.browser-default):focus:not([readonly]),
textarea.materialize-textarea:focus:not([readonly]) {
    border-bottom: none !important;
    box-shadow: 0;
}

// active tab under border

.PrivateTabIndicator-colorSecondary-280 {
    background-color: $mainPink;
    border: 2px solid $mainPink;
}

.MuiList-padding {
    padding-top: 0px !important;
}

.MuiExpansionPanelSummary-root.Mui-expanded {
    min-height: 62px !important;
    padding: 0;
}

// Bubble help button

.btn-floating {
    width: 75px !important;
    height: 75px !important;
    line-height: 80px;
    background-color: $mainGreen;
    display: flex;
    justify-content: center;
    align-items: center;
}

.btn-floating.btn-large i {
    line-height: 60px;
}

.btn-floating:hover {
    background-color: $mainPink;
}

.fixed-action-btn {
    position: fixed;
    padding-top: 0px;
    margin-bottom: 0;
    z-index: 997;
    right: 15px;
    bottom: 15px;
}

select:disabled {
    background-color: #f0f0f0;
    border: 1px solid lightgray;
}

td,
th {
    padding: 2px 5px;
}

h5 {
    margin: 0;
}

.keyFigurePanelH5 {
    margin: 10px 0 10px;
    font-size: 1.5rem;
}

label {
    padding-left: 5px;
}

button:focus {
    background-color: inherit;
}

.input-field {
    margin: 0;
    padding: 0 10px 0 0;
    display: inline-block;
}

.clickable {
    cursor: pointer;
}

.dropDownCard:after {
    position: fixed;
    width: 25px;
    height: 25px;
    top: 60px;
    right: 210px;
    content: '';
    transform: rotate(45deg);
    background: $mainBlue;
    z-index: -1;
}

.body-blue {
    margin: 5rem 0 0 0;
}

.bold {
    font-weight: bold;
}

.uploadFileInput {
    width: 0.1px;
    height: 0.1px;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    z-index: -1;
}

.noPadding {
    padding: 0;
}

.noMargin {
    margin: 0;
}

.marginTop {
    margin-top: 10px;
}

.marginSide {
    margin-left: 10px;
    margin-right: 10px;
}

div.marginTop {
    margin-top: 10px;
}

div.marginSide {
    margin-left: 10px;
    margin-right: 10px;
}

.italic {
    font-style: italic;
}

.noBorder > div.MuiInput-underline.Mui-disabled:before {
    border-bottom-style: none;
}

.shadow {
    -webkit-box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.12),
        0 1px 5px 0 rgba(0, 0, 0, 0.2);
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.12),
        0 1px 5px 0 rgba(0, 0, 0, 0.2);
}

.logo_container {
    margin-top: 40px;
    margin-bottom: 40px;
}

.logo_container > img {
    max-width: 60%;
}

.center > .MuiPickersStaticWrapper-staticWrapperRoot {
    min-width: unset;
    overflow: unset;
}

.truncate {
    text-align: center;
}

.appBar {
    height: $appBarHeight;
}

.fixed_left_bar {
    position: fixed;
    left: 0;
    top: 0;
    height: 100vh;
    padding-top: 76px;
    background-color: white;
    -webkit-box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.12),
        0 1px 5px 0 rgba(0, 0, 0, 0.2);
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.12),
        0 1px 5px 0 rgba(0, 0, 0, 0.2);
}

.MuiGrid-grid-xs-1.fixed_bar_container {
    flex-basis: 0;
}

.fixed_left_bar > .fixed_left_bar_content {
    padding-left: 5px;
    padding-right: 5px;
    padding-top: 12px;
    padding-bottom: 12px;
}

.fixed_left_bar_icon_container {
    display: flex;
    justify-content: center;
    align-items: center;
}

.fixed_left_bar_icon_container > .fixed_left_bar_icon {
    font-size: 1.8rem;
}

.tableTitle {
    padding: 10px;
    background-color: $mainBlue;
    color: white;
    font-weight: bold;
}

.MuiButtonBase-root.MuiTab-root.MuiTab-textColorPrimary.Mui-selected.MuiTab-fullWidth {
    background-color: $mainBlue;
    color: white;
}

.MuiButtonBase-root.MuiTab-root.MuiTab-textColorPrimary.MuiTab-fullWidth {
    background-color: rgb(184, 187, 191);
    color: black;
    font-weight: bold;
}

.MuiButtonBase-root.MuiTab-root.MuiTab-textColorPrimary.MuiTab-labelIcon.MuiTab-fullWidth :hover {
    color: white;
}

.MuiStepIcon-root.MuiStepIcon-completed,
.MuiStepIcon-root.MuiStepIcon-active {
    color: #057DCC;
    width: 2rem;
    height: 2rem;
}

.MuiSvgIcon-root {
    width: 2rem !important;
    height: 2rem !important;
}

.open_bubble {
    padding-top: 50px;
    position: absolute;
    height: 600px;
    width: 600px;
    background-color: #161832;
    opacity: 0.95;
    bottom: -280px;
    right: -180px;
    margin: auto;
    border-radius: 50%;
    outline: none;
    padding-right: 150px;
}

.toasterComponent {
    border-radius: 5px;
    box-shadow: 3px 3px 5px black;
}

.toasterComponent.error {
    background-color: rgb(182, 52, 47);
}

.toasterComponent.warning {
    background-color: rgb(240, 147, 38);
}

.toasterComponent.success {
    background-color: rgb(77, 137, 56);
}

.toasterComponent.info {
    background-color: $mainBlue;
}

.MuiTypography-root.MuiPaper-root.MuiPaper-elevation6.MuiSnackbarContent-root.toasterContent.MuiTypography-body2 {
    margin-bottom: 0;
}

.toasterContent > div > span {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
}

.toasterContent > div > span > svg {
    margin-right: 10px;
}

span.appbarIcon {
    cursor: pointer;
    font-size: 3rem;
    color: white;
}

#slider {
    width: 30vw;
    margin-left: 0;
}

.slides {
    background-color: $mainWhite !important;
}

.home_header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 0.5rem;
}

.item_menu_home {
    margin-right: 20px;
    font-size: 14px;
    color: black;
}

.underline {
    text-decoration: underline;
}

.home_content {
    width: 70vw;
    margin-left: auto;
    margin-right: 0;
}

.home_card {
    background-color: $mainWhite;
    height: 90vh;
    margin: 0;
}

.home_pdf_panel {
    width: 70vw;
    margin-bottom: 20px;
}

.center {
    display: flex;
    justify-content: center;
}

.slideLink {
    color: rgba(0, 0, 0, 0.87);
}

div#g-recaptcha {
    display: inline-block;
}

#home_footer {
    background-color: $mainWhite;
    width: 70vw;
    position: absolute;
    bottom: 0%;
    padding: 0.3rem 2rem;
}

.errorCCE > fieldset {
    border-color: red !important;
}

// Reset "normal" css
div.ignore-css b, div.ignore-css strong {
  font-weight: bold;
}

.cgu {
    display: block;
    width: 100%;
    background-color: $mainWhite;
    margin-bottom: 10px;
    padding: 10px;
}

.btn {
    background-color: $mainGreen;
}

.btn_help {
    background-color: $mainGreen;
    box-shadow: 3px 3px 5px grey;
}

.btn_help:hover {
    background-color: $mainPink;
    box-shadow: 3px 3px 5px grey;
}

.input_home {
    margin-right: 10px;
}

.userIcons {
    color: $mainBlue;
}

.resetPwdText {
    color: $greyHome;
}

.title_home {
    font-size: 35px;
    color: $greyHome;
    font-family: Raleway;
    font-weight: bold;
}

#btnHomeContact {
    width: 100%;
    height: 80px;
    margin-bottom: 70px;
    font-size: 1.3em;
    border-radius: 8px;
    font-weight: bold;
    letter-spacing: 0.2em;
}

.profileCard > div > div {
    margin-bottom: 0;
}

.profileCard > div div:nth-child(5) {
    font-style: italic;
    font-size: 13px;
}

.profilePicture {
    font-size: 6rem;
    color: black;
    margin: 0.3rem !important;
}

.MuiPaper-root.MuiPaper-elevation16.MuiDrawer-paper.MuiDrawer-paperAnchorLeft {
    background-color: white;
    width: 20vw;
}

.MuiExpansionPanelSummary-content {
    background-color: $mainBlue;
    -webkit-box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.12),
        0 1px 5px 0 rgba(0, 0, 0, 0.2);
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.12),
        0 1px 5px 0 rgba(0, 0, 0, 0.2);
    margin-top: 0;
}

div.MuiExpansionPanel-root.Mui-expanded {
    margin: 0;
}

.sieau-collapsible .popout > div {
    background-color: transparent;
    box-shadow: none;
    margin-bottom: 5px;
}

// Never override this class please (AutoComplete search list display)
// div.MuiPaper-root {
//     background-color: transparent;
//     box-shadow: none;
//     margin-bottom: 5px;
// }

div.cardStyle,
.cardStyleTwitter > div > iframe {
    background-color: white;
    border-radius: 5px;
    padding: 10px;
    -webkit-box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.12),
        0 1px 5px 0 rgba(0, 0, 0, 0.2);
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.12),
        0 1px 5px 0 rgba(0, 0, 0, 0.2);
}

/* Style for precipitation aggrid headers */
div.ag-header-cell {
    padding-left: 8px !important;
    padding-right: 8px !important;
}

div.noPadding {
    padding: 0;
}

div.MuiExpansionPanel-root:before {
    height: 0;
}

.MuiPaper-root.MuiPaper-elevation24.MuiDialog-paper.MuiPickersModal-dialogRoot.MuiDialog-paperScrollPaper.MuiDialog-paperWidthSm.MuiPaper-rounded {
    background-color: white;
}

// override materialui tabs background color
.MuiAppBar-colorPrimary {
    color: #fff;
    background-color: $mainWhite !important;
}

// override materialui expanded panel / accordion
.MuiExpansionPanelSummary-content {
    margin: 0;
}

.Component-root-286,
.Component-root-309 {
    padding: 0 !important;
}

.MuiExpansionPanelSummary-content {
    margin: 0 !important;
}

.MuiExpansionPanelDetails-root {
    display: contents !important;
    padding: 8px 24px 24px !important;
}

.MuiGrid-spacing-xs-2 {
    margin: 0 -8px !important;
}

.MuiSelect-select {
    min-width: 80px !important;
}

.input-field > label {
    width: 200px;
}

/*
input:not([type]), input[type=text]:not(.browser-default), input[type=password]:not(.browser-default), input[type=email]:not(.browser-default), input[type=url]:not(.browser-default), input[type=time]:not(.browser-default), input[type=date]:not(.browser-default), input[type=datetime]:not(.browser-default), input[type=datetime-local]:not(.browser-default), input[type=tel]:not(.browser-default), input[type=number]:not(.browser-default), input[type=search]:not(.browser-default), textarea.materialize-textarea {
    background-color: transparent;
    border: none;
    border-bottom: 1px solid #ffffff;
    border-radius: 0;
    outline: none;
    height: 3rem;
    width: 100%;
    font-size: 16px;
    margin: 0 0 8px 0;
    padding: 0;
    -webkit-box-shadow: none;
    box-shadow: none;
    -webkit-box-sizing: content-box;
    box-sizing: content-box;
    -webkit-transition: border .3s, -webkit-box-shadow .3s;
    transition: border .3s, -webkit-box-shadow .3s;
    transition: box-shadow .3s, border .3s;
    transition: box-shadow .3s, border .3s, -webkit-box-shadow .3s;
}

.MuiInputBase-input {
    font: inherit;
    width: 100%;
    border: 0;
    height: 1.1875em;
    margin: 0;
    display: block;
    padding: 6px 0 7px;
    min-width: 0;
    background: none;
    box-sizing: content-box;
    -webkit-tap-highlight-color: white;
    color: #ffffff;
}
*/

input[type='text']:not(.browser-default),
textarea.materialize-textarea {
    margin: 0 !important;
}

div.referencialFooter {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    background-color: lightgrey;
}

input#searchBar {
    border-color: white;
    color: white;
    margin-right: 20px;
}

img.responsive-img,
video.responsive-video {
    max-width: 90%;
}

.col .row {
    margin: 0;
}

.sieau-select-hidden {
    display: block;
}

.no-margin {
    margin: 0 !important;
}

@media screen and (max-width: 800px) {
    #slider {
        display: none;
    }

    .home_content {
        width: 100vw;
    }

    .home_pdf_panel {
        width: 100vw;
    }

    #home_footer {
        width: 100vw !important;
    }
}
